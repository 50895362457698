@import "reset";
@import "play_framework";
@import "play_colors";

.app-qr-scanner {
  background: none transparent !important;
  background-color: transparent;
  .ember-view {
    display: none !important;
  }
  .qr-scanner__button {
    width: 100%;
    height: 5%;
    max-height: 100px;
    color: black;
    background-color: #efeeec;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
  }
}

.app-login {
  .login-code {
    line-height: 30px;
  }
}

.app-environment-label {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
  font-size: 80%;
  line-height: 120%;
  padding: 2px 6px;
  pointer-events: none;
}

a:not([disabled]):not(.disabled) {
  cursor: pointer;
}

a:not(.btn):hover {
  text-decoration: underline;
}

a:not(.btn):hover i {
  text-decoration: none;
}

input {
  overflow-x: hidden;
}

input[type="radio"], input[type="checkbox"] {
  margin: .1em .45em !important;
}

input[type=button], button, input[type=submit], input[type=radio] {
  font-size: 18pt;
  line-height: 24pt;
}

// overwrite form input sizes, make chunkier!
input[type=text], input[type=number],
input[type=password], input[type=email] {
  height: 40px;
  font-size: 14pt;
  border-radius: 4px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.centering {
  text-align: center;
  overflow: hidden;
}

.center {
  text-align: center;
  margin: auto;
  padding-bottom: 15px;
  padding-top: 10px;
  margin-top: 10px;
}

.first-center {
  margin-top: 15px;
}

.login {
  margin-top: 33px;
  margin-bottom: 38px;
}

.btn {
  font-family: VistaSlabBold, Trebuchet, Verdana, sans-serif;
}

.btn-large {
  font-size: 18pt;
}

.btn-small {
  font-size: 14pt;
  padding: 6px 10px;
}

.orange {
  color: #d75b0b;
}

.btn {
  &:active:not([disabled]), &.active:not([disabled]) {
    position: relative;
    top: 1px;
    box-shadow: none;
  }
}

.play-loading {
  h1 {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }
}

.custom-footer-image {
  margin-top: 1em;
  text-align: center;
  img {
    max-width: 100%;
  }
}

.app-playthrough-header {
  .custom-header-image {
    text-align: center;
    overflow: hidden;
    img {
      height: 0;
      vertical-align: top;
    }
    // resizeHeader overrides
  }
  .container-fluid {
    padding: 0;
    position: relative;
  }
  .team-points {
    pointer-events: none;
    position: absolute;
    top: 12px;
    right: 5px;
  }
  .admin-tap-target {
    position: absolute;
    right: -20px;
    top: 0;
    width: 60px;
    height: 44px;
  }
  li {
    line-height: inherit;
    position: relative;
    a {
      .badge {
        position: relative;
        top: -3px;
      }
      i {
        position: relative;
        top: -5px;
      }
      span.item-caption {
        position: absolute;
        width: 50px;
        left: 0px;
        top: 22px;
        text-align: center;
        font-size: 12px;
      }
      &.disabled, &.disabled:hover {
        cursor: default;
      }
      &.more-tab:hover {
        cursor: pointer;
      }
    }
  }
}

#app {
  padding-top: 10px;
}

.app-playthrough-login {
  text-align: center;
}

.app-playthrough-intro {
  text-align: center;
  .alert-closed {
    margin-bottom: 1em;
  }
}

.app-playthrough-admin {
  .buildstamp {
    margin-top: 2em;
  }
  .align-center {
    text-align: center;
  }
  .admin-options {
    margin-top: 15px;
    label {
      display: inline;
      line-height: 35px;
    }
  }
  .admin-role {
    label {
      padding-left: 15px;
    }
  }
  .admin-actions {
    text-align: center;
    .btn {
      margin: 10px;
      width: 30%;
    }
  }
}

.app-play-home {
  text-align: center;
  .play-ended-message,
  .play-paused-message,
  .game-ended-early-message {
    white-space: pre-line;
  }
}

.game-paused-message, .app-playthrough-intro .game-welcome {
  white-space: pre-line;
}

.app-play-home, .app-playthrough-intro {
  .login-roles {
    margin-top: 1em;
    text-align: center;
    font-size: 12pt;
    line-height: 120%;
  }
}

.app-play-leaderboard {
  h3 {
    font-size: 20px;
    line-height: 1;
    color: #ff7513;
    font-weight: 700;
    text-align: center;
    margin: 20px 0;
    text-transform: uppercase;
  }
  .leaderboard-team {
    min-height: 30px;
    &:nth-child(even) {
      background: #ffffff99;
    }
    &:nth-child(2) {
      .team-place {
        display: none;
      }
      background: url(../images/icon-cup-1.png) 11px center no-repeat #ffffff99;
      background-size: 27px;
      padding-left: 48px;
      padding-top: 7px;
      padding-bottom: 3px;
    }
    &:nth-child(3) {
      .team-place {
        display: none;
      }
      background: url("../images/icon-cup-2.png") 11px center no-repeat;
      background-size: 27px;
      padding-left: 48px;
      padding-top: 7px;
      padding-bottom: 3px;
    }
    &:nth-child(4) {
      .team-place {
        display: none;
      }
      background: url("../images/icon-cup-3.png") 11px center no-repeat #ffffff99;
      background-size: 27px;
      padding-left: 48px;
      padding-top: 7px;
      padding-bottom: 3px;
    }
    .team-place{
      background: white;
      border-radius: 50%;
      line-height: 24px;
      width: 24px;
      text-align: center;
      height: 24px;
      margin-right: 5px;
      border: 3px solid #ff7513;
      display: inline-block;
    }
    .team-name-place {
      padding: 0;
      margin: 0;
      overflow: hidden;
    }

    .team-points {
      width: 20%;
      padding: 0;
      margin: 0;
      float: right;
      text-align: right;
    }
    overflow: hidden;
    padding: 5px 10px;
    font-family: VistaSansMedium, Trebuchet, Verdana, sans-serif;
  }
  .leaderboard-team + .leaderboard-team {
    border-top: 1px solid;
    border: 0;
  }
  .leaderboard-omitted {
    text-align: center;
    padding: 4px;
    font-style: italic;
  }
}

.app-play-vote {
  .voting-notstarted {
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  .voting-buttons {
    margin-top: 1em;
    .voting-row {
      margin-bottom: 0.5em;
    }
    text-align: center;
  }
}

.app-play-sync {
  .sync-waypoint {
  }
  .sync-header {
    .uploads-completed-stats {
      float: right;
    }
    overflow: auto;
  }
  .sync-uploads {
    .sync-upload {
      .upload-progress {
        .progress {
          margin-bottom: 0;
          width: 80%;
          float: left;
        }
        button {
          width: 20%;
          float: left;
        }
        overflow: auto;
      }
    }
  }
}

.app-play-sync-status {
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  text-align: center;
  -webkit-transition: top 0.5s;
  width: 100%;
  z-index: 1030;
  -webkit-transition: max-height 0.5s;
  &.all-tabs {
    top: 88px;
  }
  &.synced {
    max-height: 0;
  }
  &.failure {
    max-height: 22px;
    & + .app-body {
      padding-top: 65px;
    }
  }
  &.syncing {
    max-height: 22px;
    .progress {
      border-radius: 0;
      position: relative;
    }
    .progress .bar {
      height: 22px;
      -webkit-transition: width 0.5s;
    }
    .progress .num-uploads {
      position: absolute;
      top: 0;
      padding: 0px 10px;
      border-radius: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    & + .app-body {
      padding-top: 65px;
    }
  }
}

.app-play-retry-list {
  tr {
    font-size: 12pt;
    line-height: 120%;
  }
}

.app-play-retry {
  .play-retry-header {
    margin: -10px -20px 5px -20px;
    a {
      padding-right: 0.5em;
    }
    padding: 2px 15px;
    font-size: 12pt;
    line-height: 120%;
  }
}

.separator-with-text {
  text-align: center;
  margin-top: -8px;
  margin-bottom: -2px;
  .line {
    width: 100%;
    height: 1px;
    position: relative;
    top: 0.7em;

  }
  .content {
    letter-spacing: 1px;
    padding: 0px 5px;
    font-size: 10pt;
    position: relative;
  }
}

.app-play-waypoint {

  iframe {
    display: block;
    width: 100%;
    height: 240px;
  }
  .waypoint-title {
    line-height: 120%;
    text-align: center;
  }
  .waypoint-instructions {
    img {
      width: 100%;
    }
    ul, ol {
      padding-left: 1em;
      padding-bottom: 0.5em;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    pre {
      word-wrap: break-word;
    }
    p, pre {
      margin-bottom: 10px;
      em {
        font-style: italic;
      }
    }
  }
  .submission-parts {
  }
  .submission-part {
    margin: 1em 0 0.5em;
  }
  .submission-widget {
    .alert {
      margin-bottom: 10px;
    }
    form {
      margin: 0;
    }
    label.radio-label {
      overflow: auto;
      input {
        float: left;
        margin-top: -1ex;
      }
      div {
        float: left;
        width: 85%;
      }
    }
  }
  .submission-media-widget {
    .hidden {
      display: none;
    }
    .video-review-container {
      text-align: center;
      video.video-review {
        width: 100%;
      }
      .alert-upload-retry {
        &:hover {
          cursor: pointer;
        }
        .close {
          color: darkred;
          font-size: 24px;
          top: 2px;
        }
      }
      .video-note {
        color: dimgrey;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .alert-native-media-pending {
      display: none;
    }
    .uploading-message {
      text-align: center;
    }
    .alert-error {
      margin-bottom: 0;
    }
    input[type=file] {
      height: 0;
      width: 0;
      visibility: hidden;
    }
    label {
      box-sizing: border-box;
      width: 100%;
      padding: 0.5em;
      font-size: 28pt;
      line-height: 32pt;
      text-align: center;
      cursor: pointer;
    }
  }
  #submitButton {
    width: 100%;
  }
  .current-hint {
    margin-top: 0.5em;
  }
  .num-attempts {
    float: right;
  }
  .get-hints {
  }
  .footnote {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (min-width: 768px) {
  .app-play-waypoint .waypoint-instructions img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: initial;
  }
}

.app-play-modal {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 400px;
  word-break: break-word;
  z-index: 1040;

  .modal-content {
    z-index: 1050;
    position: relative;
    top: 20px;
    width: 280px;
    margin: 0 auto;
    padding: 10px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
  }

  .social-select-content {
    .saving-spinner {
      margin-top: 5px;
    }
    .social-select-btn {
      margin-bottom: 2px;
    }
    .social-select-cancel-btn {
      margin-top: 20px;
    }
  }
}

.app-play-message {

  &.with-points .message-no-image {
    height: 20px;
  }

  .message-content {
    border-radius: 3px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    padding: 10px;
    position: relative;
    top: 20px;
    width: 280px;
    z-index: 1050;
    .message-image {
      width: 250px;
      height: 250px;
    }
    .message-text {
    }
    .skip-share-button {
      margin-bottom: 5px;
    }
    .message-points {
      float: right;
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px;
      border-bottom-left-radius: 3px;
    }
    .share-message {
      border-radius: 4px;
      font-size: 14pt;
      height: 55px;
      line-height: 25px;
      margin: 0;
      overflow: hidden;
      resize: none;
      text-align: center;
    }
    .message-footer {
      text-align: center;
      font-size: 14px;
    }
  }
  &.with-image {
    .message-image {
      display: block;
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      max-width: inherit;
      margin-bottom: 0.5em;
    }
    .message-image-and-text {
      position: relative;
    }
    .message-text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 280px;
      padding: 5px 0 5px 0;
    }
    .btn-group {
      margin-top: 10px;
    }
  }
  &.no-image {
    .message-text {
      margin: 1em 0;
    }
  }

  .error {
    border: 1px solid red;
    margin-bottom: 5px;
    padding: 5px;
  }
}

.app-play-vote-select {
  .vote-select-header {
    padding: 5px;
    height: 3.7em;
    .choose-message {
      text-align: center;
    }
  }
  .vote-select-content {
    margin-top: 20px;
    overflow-y: scroll;
    .select-item {
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 5px;
      height: 100px;
      &.is-selected {
      }
      &.is-deselected {
        img, .title, .instructions {
          opacity: 0.3;
        }
      }
      img {
        float: left;
        margin-right: 5px;
      }
      .select-item-content {
        .title {
        }
        .instructions {
          height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.app-play-vote-admin {
  .voting-not-started {
    text-align: center;
  }
  .clear-voting {
    margin-top: 1em;
  }
  .mission-title {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .voting-on-mission-list {
  }
  .voting-on-waypoint-list {
    li {
      cursor: pointer;
      padding: 4px 4px 4px 1em;
      .waypoint-is-setting {
        opacity: 0.75;
      }
    }
  }
}

.app-play-mode-home {
  .title {
    text-align: center;
    margin: 0;
  }
}

.app-play-mode-persistent-home {
  .title {
    margin-bottom: 10px;
  }
  .missions-loading, .missions-empty {
    text-align: center;
    margin-top: 1em;
  }
  .persistent-missions-list button, .persistent-waypoints-list button {
    margin-bottom: 10px;
    text-align: center;
  }

}

.app-play-mode-challenge-home {

  .title {
    text-align: center;
    margin: 0;
  }
  .prompt {
    text-align: center;
    font-size: 12pt;
    line-height: 120%;
    margin-top: 0;
  }
  .alert-in-progress {
    margin-bottom: 0.5em;
  }

  .send {
    margin-top: 0;
    .input-other-pin {
      text-align: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
    .btn-other-pin {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    margin-bottom: 0.5em;
  }

  .team-codes {
    text-align: center;
    padding-bottom: 1em;
    width: 300px;
    margin: 0 auto;
    .team-pin {
      text-align: center;
    }
  }
}

#errorModal {
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
  .error-backdrop {
    height: 100%;
    width: 100%;
  }
  .error-content {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    left: 10%;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 80px;
    width: 80%;
    .error-header {
    }
    .error-body {
      margin-bottom: 15px;
    }
    .error-footer {
    }
  }
}

.app-play-info {
  img {
    width: 100%;
  }
  iframe {
    height: 600px;
    width: 100%;
  }
}

.play-gallery {
  position: relative;
  a, a:hover {
    text-decoration: none;
  }
  .link-to-teams {
  }
  .team-name {
    margin: 0;
    padding: 5px 0 10px;
    text-align: center;
  }
  .gallery-media-item {
    img, video {
      width: 100%;
    }
  }
  .gallery-no-media {
    margin: 2em 0;
    text-align: center;
  }
  .gallery-team-link {
    margin-bottom: 10px;
  }
}

$orange: #ff7415;

.loader {
  color: $orange;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease;
  animation: load6 1.7s infinite ease;
  animation-delay: 0.5s;
}

.exit-button-container {
  text-align: right;
  padding: 40px 0 15px;
  .btn {
    width: 50%;
  }
}

.hybrid-chat {
  &__chat-loading {
    display: grid;
    place-items: center;
    height: 100%;
  }

  &__messages-wrap {
    overflow-y: auto;
    height: 70vh;
    width: 100%;
    box-shadow: 0px 37px 48px rgba(194, 194, 194, 0.8);
    &::-webkit-scrollbar {
      width: 5px !important;
      background: #9aa1ad !important;
      border-radius: 12px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9aa1ad !important;
    }

    &--message-wrap {
      display: flex;
      width: 100%;
      flex-direction: row;

      &.right-message {
        flex-direction: row-reverse;
        align-items: end;
      }

      &__contain {
      display: flex;
      text-align: start;
      padding: 8px;
      width: 80%;
      flex-direction: column;
        .message {
          border-radius: 4px;
          padding: 2px 12px;
          overflow: hidden;
          overflow-wrap: break-word;
          font-size: 14px;
          width: inherit;
          font-family: Sofia Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 19px;
          color: #fff;
          background-color: $primary;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &___time {
          width: inherit;
          line-height: 20px;
          font-size: 14px;
        }
      }

      &.right-message > &__contain {
        align-items: flex-end;
        &___time {
          width: auto !important;
        }
      }
    }
  }

  &__message-input {
    display: flex;
    width: 100%;
    align-self: center;
    border: 0px;
    flex-direction: column;

    textarea {
      background: rgb(252, 251, 251);
      line-height: 20px;
      padding: 12px;
      max-width: 100%;
      width: 100%;
    }
  }
}

@-webkit-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
