@import "font-awesome";
@import "fonts";

$baseBorderRadius: 3px;


body {
  font-size: 14px;
  line-height: 20px;
  -webkit-transition: background-color 0.4s;
  -webkit-touch-callout: none;
}

body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body, input, button, select, textarea {
  font-family: VistaSansBook, Trebuchet, Verdana, sans-serif;
}

table, body, input, label {
  font-size: 14pt;
  line-height: 130%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
form { margin-bottom: 20px; }
input[type=text], input[type=number], textarea {
  border: 1px solid;
  box-shadow: inset 0 1px 0px rgba(0,0,0,.2);
  line-height: 20px;
  margin-bottom: 10px;
  padding: 4px 6px;
  -webkit-appearance: none;
  &:focus { box-shadow: none; }
}
.input-block-level {
  box-sizing: border-box;
  width: 100%;
}
input[type=submit] {
  box-shadow: 0px 1px 0px #888888;
  -webkit-box-shadow: 0px 1px 0px #888888;
  -webkit-appearance: button;
}

a, a:hover { text-decoration: none; }

strong, .bold, .table th, h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: VistaSansMedium, Trebuchet, Verdana, sans-serif;
}
.table {
  text-align: left;
  width: 100%;
}
.table-condensed th, .table-condensed td {
  padding: 5px;
}

// Reduce bottom margin. Add a nice white shadow to the bottom
h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  margin-bottom: 0.25em;
  font-family: VistaSlabBold, Trebuchet, Verdana, sans-serif;
  // text-shadow: white 0 1px;
}
h1, h2, h3 { line-height: 40px; }
h1 { font-size: 38.5px; }
h2 { font-size: 31.5px; }
h3 { font-size: 24.5px; }
h5 { font-size: 14px; }


p {
  margin: 0 0 10px;
  // text-shadow: white 0 1px;
}

.btn {
  border: 1px solid #bbb;
  border-radius: $baseBorderRadius;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 12px;
  text-align: center;
  vertical-align: middle;
  &.disabled, &[disabled] {
    cursor: default;
    opacity: 0.65;
  }
}
.btn-large {
  font-size: 18pt;
  line-height: 22pt;
  padding: 11px 19px;
}
.label {
  border-radius: $baseBorderRadius;
  display: inline-block;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  padding: 2px 4px;
  vertical-align: baseline;
  white-space: nowrap;
}

.navbar-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}

.navbar-inner {
  min-height: 40px;
  .brand {
    background-image: url(../images/logo-nav-highlight.png);
    background-size: 98px 40px;
    display: block;
    float: left;
    height: 40px;
    margin: 0 20px 0 0;
    padding: 0;
    width: 98px;
  }
  .nav {
    list-style: none;
    overflow: hidden;
    padding-right: 70px;
    li {
      float: left; padding: 10px; width: 30px; text-align: center;
      > a { display: block; }
    }
  }
}

.align-center { text-align: center; }
.small { font-size: 12pt; line-height: 120%; }
.alert {
  border-radius: $baseBorderRadius;
  margin-bottom: 20px;
  padding: 8px 32px;
  text-align: center;
}
.alert .close { position: relative; right: -21px; }
.close {
  cursor: pointer;
  float: right;
  font-weight: bold;
  // opacity: 0.5;
  line-height: 20px;
}
.header-top { margin-top: 0; }


#appContainer { padding-bottom: 30px; }
.app-body {
  padding-top: 45px;
  padding-bottom: 45px;
  -webkit-transition: padding-top 0.5s;
  > div {
    padding-bottom: 15vh;
  }
}
.app-body-container {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: auto;
}
.app-playthrough-header.two-rows ~ .app-body {
  -webkit-transition: padding-top 0.5s;
}

.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

.navbar-fixed-top, .app-play-sync-status, #errorModal { width: 100%; }

@media screen and (min-width: 768px) {
  .navbar-inner { margin: 0 auto; width: 75%; }
  .app-body { margin: 0 auto; width: 75%; }
}
