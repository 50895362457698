
// theme
$orange: #ff7415;
$background:            #efeeec !default; // (light off-white)
$foreground:            #333333 !default; // (dark gray)
$primary:               #f47421 !default; // (gogame orange)
$success:               #46a546 !default; // (green)
$danger:                #9d261d !default; // (red)

$backgroundAlt:         #ffffff !default;
$foregroundAlt:         #333333 !default;
$foregroundInverse:     #ffffff !default;

$headerBackground:      #ffffff !default;
$navBarBackground:      #ffffff !default;

$headerAlign: center !default;
$footerAlign: center !default;

$font:     #000000 !default;

$facebook:              #3b5998 !default;
$twitter:               #4099FF !default;

// ==============================================================

// typography
$linkColor: $primary;

// header
$headerColor: $foregroundAlt;

$borderColor: darken($background, 10%);

// buttons
$btnBackground: darken($background, 5%);
$btnColor: $foreground;
$btnBorder: rgba(0,0,0,0.25);

$btnPrimaryBackground: $primary;
$btnPrimaryColor: $foregroundInverse;

$btnSuccessBackground: lighten($success, 10%);
$btnSuccessColor: $foregroundInverse;

$btnDangerBackground: lighten($danger, 20%);
$btnDangerColor: $foregroundInverse;

// navbar
$activeBackground: $background;
$navLink: lighten($foreground, 30%);

// labels
$labelColor: white;
$labelBackground: #999;

// alerts
$alertBackground:         darken($background, 10%);
$alertText:               darken($background, 50%);
$alertBorder:             darken($background, 15%);

$successBackground:       lighten($success, 40%);
$successText:             $success;
$successBorder:           lighten($success, 30%);

$dangerBackground:        lighten($danger, 50%);
$dangerText:              $danger;
$dangerBorder:            lighten($danger, 30%);

// tables
$tableStripedBackground:  $background;

$grayDarker:            #222;
$grayDark:              #333;
$gray:                  #555;
$grayLight:             #999;
$grayLighter:           #eee;

body { color: $font; }

a { color: $primary; }

// inputs
input[type=text], input[type=number], textarea { border-color: $borderColor; }
input:focus, textarea:focus {
  border-color: $primary;
  outline: 0;
}
button:focus, .btn:focus { outline: 0;}

hr {
  border-top: 1px solid $grayLight;
  border-bottom: 1px solid darken($grayLighter, 8%);
}

// tables
.table { background-color: white; }
.table-striped tbody>tr:nth-child(odd)>td,
.table-striped tbody>tr:nth-child(odd)>th {
  background-color: $tableStripedBackground;
}
.table th, .table td {
  border-top: 1px solid #ddd;
}

// navbar
.navbar {
  background-color: $navBarBackground;
  border-bottom: 1px solid darken($navBarBackground, 20%);
  .custom-header-image {
    background-color: $headerBackground;
    text-align: $headerAlign;
  }
  .nav {
    li:not(.active):not(.disabled):hover {
      background-color: darken($navBarBackground, 10%);
    }
    a { color: $headerColor; }
    .active {
      background-color: $activeBackground;
      a, .item-caption { color: $primary; }
    }
  }
}
.custom-footer-image { text-align: $footerAlign; }

// buttons
.btn {
  background-color: $btnBackground;
  border-color: $btnBorder;
  box-shadow: 0 1px 0 rgba(0,0,0,.25);
  color: $btnColor;
  &:hover:not([disabled]) { background-color: darken($btnBackground, 10%); }
}
.btn-primary {
  background-color: $btnPrimaryBackground;
  border-color: darken($primary, 10%);
  box-shadow: 0 1px 0 darken($primary, 20%);
  color: $btnPrimaryColor;
  text-shadow: darken($primary, 20%) 0 -1px;
  &:hover:not([disabled]) { background-color: darken($btnPrimaryBackground, 10%); }
}
.btn-success {
  background-color: $btnSuccessBackground;
  border-color: darken($success, 10%);
  box-shadow: 0 1px 0 darken($success, 20%);
  color: $btnSuccessColor;
  text-shadow: darken($success, 20%) 0 -1px;
  &:hover:not([disabled]) { background-color: darken($btnSuccessBackground, 10%); }
}
.btn-danger {
  background-color: $btnDangerBackground;
  border-color: darken($danger, 10%);
  box-shadow: 0 1px 0 darken($danger, 20%);
  color: $btnDangerColor;
  text-shadow: darken($danger, 20%) 0 -1px;
  &:hover:not([disabled]) { background-color: darken($btnDangerBackground, 10%); }
}
.btn-large { font-size: 18pt; }

.btn.facebook-btn {
  background-color: $facebook;
  color: white;
  &:hover { background-color: darken($facebook, 20%); }
}

.btn.twitter-btn {
  background-color: $twitter;
  color: white;
  &:hover { background-color: darken($twitter, 20%); }
}

.label {
  background-color: $labelBackground;
  color: $labelColor;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}


// alerts
.close { color: darken($alertText, 20%); }
.alert {
  background-color: $alertBackground;
  border: 1px solid $alertBorder;
  color: $alertText;
}
.alert-success {
  background-color: $successBackground;
  border-color: $successBorder;
  color: darken($successText, 10%);
  .close { color: $successText; }
}
.alert-success h4 { color: $successText; }
.alert-danger, .alert-error {
  background-color: $dangerBackground;
  border-color: $dangerBorder;
  color: $dangerText;
}
.alert-danger h4, .alert-error h4 { color: $dangerText; }

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}


// app
.app-environment-label { background: $gray; color: white; }

.app-playthrough-header {
  li {
    a {
      &.disabled, &.disabled:hover {
        color: $grayLighter;
        span { color: $grayLighter; }
      }
    }
  }
  .team-points { background-color: darken($navBarBackground, 20%); }
}

.progress {
  background-color: darken($background, 10%);
 .bar { background: $primary; }
}

.app-play-sync-status {
  &.synced {
    background: $btnPrimaryBackground;
    color: white;
  }
  &.failure {
    background: $btnDangerBackground;
    color: white;
  }
}

.app-play-retry {
  .play-retry-header {
    background-color: darken($background, 30%);
    color: lighten($background, 20%);
    a { color: darken($background, 5%); }
  }
}

.highlight-text {
  color: $primary;
}

.subtle-text {
  color: $grayLight;
}

.separator-with-text {
  .line {
    background: lighten($grayLight, 8%);
  }
  .content {
    background: $background;
    color: $grayLight;
  }
}

.app-play-waypoint {
  .submission-media-widget {
    label {
      border: 4px dashed $grayLight;
      color: $grayLight;
    }
  }
  .footnote {
    color: $grayLight;
  }
}

.app-play-modal {
  background: rgba(0, 0, 0, 0.5);
  .modal-content {
    background: white;
    border: 5px solid $primary;
    &.social-select-content {
      border: 5px solid $facebook;
    }
  }
  .hide {
    -webkit-animation: fadeout 0.8s; /* Safari, Chrome and Opera > 12.1 */
  }
  .show {
    -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
  }
  .hidden {
    opacity: 0
  }
  .shown {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.app-play-message {
  .message-content {
    .message-footer {
      color: $grayLight;
    }
  }
  &.message-success {
    .message-content { border: 5px solid $success; }
    .message-points { background: $success; color: white; }
    .btn-primary { @extend .btn-success; }
  }
  &.message-error, &.message-failure {
    .message-content { border: 5px solid $danger; }
    .message-points { background: $danger; color: white; }
    .btn-primary { @extend .btn-danger; }
  }
  &.message-info {
    .message-content { border: 5px solid $primary; }
    .message-points { background: $primary; color: white; }
  }
  &.with-image {
    .message-text {
      background: rgba(255, 255, 255, 0.85);
    }
  }

  .error {
    color: $dangerText;
    background-color: $dangerBackground;
    border-color: $dangerBorder;
  }
}

.app-play-vote-select {
  .vote-select-content {
    .select-item {
      &:nth-child(even) { background: darken($background, 10%); }
    }
  }
}

.app-play-vote-admin {
  .mission-title { color: $grayLight; }
  .voting-on-waypoint-list {
    li {
      &.voting-active {
        background: $linkColor; color: white;
        a { color: white; }
      }
    }
  }
}

#errorModal {
  .error-backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
  .error-content {
    background: #f4f3f2;
  }
}

body, #appContainer { background: $background; }
